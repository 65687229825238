
        if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
          console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
        } else {
          var prevRefreshReg = window.$RefreshReg$;
          var prevRefreshSig = window.$RefreshSig$;
          window.$RefreshReg$ = (type, id) => {
            window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
          }
          window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
        }
      var _s = $RefreshSig$();
import { jsx, jsxs } from "react/jsx-runtime";
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1698839577000";
}
import { useState } from "react";
import { TextInput, PasswordInput, Paper, Container, Group, Button, Image } from "@mantine/core";
import { redirect } from "@remix-run/node";
import { Form } from "@remix-run/react";
import { login, createUserSession } from "~/utils/session.server";
async function action({
  request
}) {
  const formData = JSON.parse((await request.formData()).get("formData"));
  const result = await login({
    username: formData.username,
    password: formData.password
  });
  if (result.passwordMatch) {
    return createUserSession(result.user._id, "/");
  }
  return redirect("/login");
}
function AuthenticationTitle() {
  _s();
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });
  return /* @__PURE__ */jsx(Container, {
    size: 420,
    my: 40,
    children: /* @__PURE__ */jsxs(Paper, {
      withBorder: true,
      shadow: "md",
      p: 30,
      mt: 30,
      radius: "md",
      children: [/* @__PURE__ */jsx("div", {
        style: {
          display: "flex",
          justifyContent: "center",
          marginBottom: 10
        },
        children: /* @__PURE__ */jsx(Image, {
          src: "/images/logo2.png",
          width: 200
        })
      }), /* @__PURE__ */jsxs(Form, {
        method: "post",
        children: [/* @__PURE__ */jsx(TextInput, {
          label: "Mobile Number/Email",
          placeholder: "you@sfsrolls.com/4125555555",
          required: true,
          value: formData.username,
          onChange: e => setFormData({
            ...formData,
            username: e.target.value
          })
        }), /* @__PURE__ */jsx(PasswordInput, {
          label: "Password",
          placeholder: "Your password",
          required: true,
          mt: "md",
          value: formData.password,
          onChange: e => setFormData({
            ...formData,
            password: e.target.value
          })
        }), /* @__PURE__ */jsx(Group, {
          position: "apart",
          mt: "lg"
        }), /* @__PURE__ */jsx(Button, {
          type: "submit",
          fullWidth: true,
          mt: "xl",
          children: "Sign in"
        }), /* @__PURE__ */jsx(TextInput, {
          type: "hidden",
          name: "formData",
          value: JSON.stringify(formData),
          onChange: () => {}
        })]
      })]
    })
  });
}
_s(AuthenticationTitle, "XBAwkMJaWwC3s1gAV6PylxglOaM=");
_c = AuthenticationTitle;
export { action, AuthenticationTitle as default };
var _c;
$RefreshReg$(_c, "AuthenticationTitle");
        window.$RefreshReg$ = prevRefreshReg;
        window.$RefreshSig$ = prevRefreshSig;
      